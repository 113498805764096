@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl font-bold;
    }
    h2 {
        @apply text-xl font-bold;
    }
    h3 {
        @apply text-lg font-bold;
    }
    h4 {
        @apply font-bold;
    }
    code {
        @apply bg-gray-800 p-0.5
    }
    a.md-link {
        font-weight: bold;
        background: -webkit-linear-gradient(rgb(134 239 172), #67e8f9);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
